import {ecomAppDefID, Events, SPECS} from './constants';
import {withMembersArea} from '@wix/members-area-integration-kit';
import webLogger from '@wix/web-bi-logger';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';
import {getAppManifest} from '../common/appManifest/appManifest';
import {DependantApps} from './services/DependantApps';
import {StyleParams} from './services/StyleParams';
import {translateFunctionFactory} from '../common/translations/translations';
import {
  setSentryInstance,
  withErrorReportingWrapping,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {
  addMembersArea,
  addPage,
  addWidget,
  openEcomBillingPage,
  updateEcomPlatformInstallations,
  updateEcomPagesForPagesPanel,
  getEcomPlatformAppManifest,
  setStateForPages,
  setStateForStoresPages,
  setThankYouPageStyleParams,
  installWishlistApp,
  addRouter,
  getRouter,
  connectPageToRouter,
} from './editor-script-partials/publicApi';
import {handleOnEvent} from './editor-script-partials/onEvent';
import {doTransaction} from './transaction';
import {handleAction} from './editor-script-partials/handleAction';
import {createAppApi} from './editor-script-partials/appApi';
import {ExperimentsApi} from '../common/experiments/ExperimentsApi';
import {AppManifestBuilder} from '@wix/app-manifest-builder';
import {EditorSDK, ContextParams, PageRef, RouterRef} from '@wix/platform-editor-sdk';
import {EcomPlatformInstallationState} from './services/EcomPlatformInstallationState';
import {EcomComponent} from '@wix/ecom-platform-sdk';
import {createSentryInstance} from '../common/monitoring';
import {EcomStyleParams} from '@wix/ecom-platform-sdk/dist/es/src/types';
import {AppApiModel} from '@wix/wixstores-client-core/dist/src/types/app-api-model';
import {createOOIWebBILogger} from '@wix/wixstores-client-core';
import {IInstallReviewsPluginProps, installReviewsPlugin} from './editor-script-partials/reviews';

let appToken;
let options: ContextParams;
let t: (keyToTranslate: string) => string;
let locale: string = 'en';
let webBiLogger: WebBiLogger;
let sdk: EditorSDK;
let experimentsApi: ExperimentsApi;
let dependantApps: DependantApps;
let appApi: AppApiModel;
const ecomPlatformInstallationState = new EcomPlatformInstallationState();
let styleParams: StyleParams;
let storeId: string;

async function provisionStore(instance) {
  return fetch('/_api/wix-ecommerce-renderer-web/store-manager/provision-store', {
    method: 'post',
    headers: {
      Authorization: instance,
    },
  });
}

function registerEvents() {
  return sdk.document.application.registerToCustomEvents('', {eventTypes: [Events.dashboardClosed]});
}

async function editorReady(_editorSDK: EditorSDK, _appToken, _options) {
  options = _options;
  appToken = _appToken;
  sdk = _editorSDK;

  const instance: string = options.initialAppData.instance;
  experimentsApi = new ExperimentsApi(instance);
  await experimentsApi.fetch();

  setSentryInstance(createSentryInstance(options.monitoring));
  // wrapAsyncFunctionsWithPromise(true);
  await registerEvents();

  const encodedInstance = instance.substring(instance.indexOf('.') + 1);
  const parsedInstance = JSON.parse(atob(encodedInstance));
  storeId = parsedInstance.instanceId;
  const isMerchant = true;

  const user = {uuid: parsedInstance.uid};
  webBiLogger = createOOIWebBILogger({
    user,
    biLoggerFactory: webLogger.factory,
    biToken: parsedInstance.biToken,
    defaults: {
      storeId,
      isMerchant,
      appName: 'wixstores worker',
    },
  });

  dependantApps = new DependantApps(sdk, instance, options.initialAppData.metaSiteId, webBiLogger);
  styleParams = new StyleParams(sdk, options.initialAppData.applicationId);
  locale = (await sdk.editor.environment.getLocale()) || locale;
  t = await translateFunctionFactory(locale);
  appApi = createAppApi({sdk, dependantApps, styleParams, t, experimentsApi, webBiLogger});
  await sdk.editor.setAppAPI('', withErrorReportingWrapping(appApi));
  await doTransaction(sdk, async () => {
    await dependantApps.installMySubscriptionsPageInMembersAreaIfNeeded({biData: options.biData});
  });

  if (experimentsApi.enabled(SPECS.ShouldCallProvisionToOnlineStores)) {
    await provisionStore(instance);
  }
}

export function getPublicApi(editorSDK: EditorSDK, contextParams: ContextParams) {
  return {
    addPage: (pageId: string, managingAppDefId = ecomAppDefID, shouldNavigate = false) =>
      addPage(editorSDK, pageId, managingAppDefId, shouldNavigate),
    addWidget: (widgetId: string, addToAllPages: boolean) => addWidget(editorSDK, widgetId, addToAllPages),
    addRouter: (prefix: string) => addRouter(editorSDK, prefix),
    getRouter: (routerRef: RouterRef) => getRouter(editorSDK, routerRef),
    connectPageToRouter: (connectPageOptions: {pageRef: PageRef; routerRef: RouterRef; pageRoles: string[]}) =>
      connectPageToRouter(editorSDK, connectPageOptions),
    addMembersArea: (
      editorType: string,
      firstInstall: boolean,
      biData?: {origin?: string},
      siteCreationFlow: boolean = false
    ) => addMembersArea(editorType, firstInstall, biData, siteCreationFlow),
    setStateForStoresPages: () => setStateForStoresPages(editorSDK),
    setStateForPages: () => setStateForPages(editorSDK),
    openEcomBillingPage: () => openEcomBillingPage(editorSDK),
    updateEcomPlatformInstallations: (appDefId: string, installedComponents: EcomComponent[]) =>
      updateEcomPlatformInstallations(ecomPlatformInstallationState, appDefId, installedComponents),
    updateEcomPagesForPagesPanel: () => updateEcomPagesForPagesPanel(editorSDK, ecomPlatformInstallationState),
    getEcomPlatformAppManifest: () => getEcomPlatformAppManifest(t, locale),
    getInstance: () => contextParams.initialAppData.instance,
    setThankYouPageStyleParams: (thankYouPageStyleParams: EcomStyleParams) =>
      setThankYouPageStyleParams({
        sdk: editorSDK,
        ecomPlatformInstallationState,
        styleParams: thankYouPageStyleParams,
        experimentsApi,
      }),
    installWishlistApp: () => installWishlistApp({sdk: editorSDK, dependantApps, experimentsApi, t, webBiLogger}),
    installReviewsPlugin: ({targetSlot, widgetPluginPointer}: IInstallReviewsPluginProps) =>
      installReviewsPlugin({editorSDK, targetSlot, widgetPluginPointer}),
  };
}

export const editorScript = withMembersArea(
  withErrorReportingWrapping({
    editorReady,
    handleAction: (data, editorSdk) =>
      handleAction(data, {sdk: editorSdk, dependantApps, ecomPlatformInstallationState, webBiLogger}),
    getAppManifest: ({appManifestBuilder}: {appManifestBuilder: AppManifestBuilder}, editorSDK: EditorSDK) =>
      getAppManifest({
        t,
        locale,
        token: appToken,
        hasStoresPremium: experimentsApi.hasStoresPremium,
        exps: experimentsApi.experiments,
        appManifestBuilder,
        editorSDK,
        options,
        pagesPanelTitle: t('Pages_Panel_ecomPlatformPages'),
      }),
    onEvent: (data, editorSDK) => handleOnEvent(data, editorSDK, dependantApps, experimentsApi),
    getControllerPresets: () => Promise.resolve([]),
    exports: (editorSDK: EditorSDK, contextParams: ContextParams) => ({public: getPublicApi(editorSDK, contextParams)}),
  }),
  {installAutomatically: false}
);

export type EcomPublicApi = ReturnType<typeof getPublicApi>;
