import {ECOM_MEMBERS_AREA_DEFAULT_PAGES, ecomAppDefID} from '../constants';
import {
  addApplications,
  installMembersArea,
  registerMembersAreaApps,
  isMembersAreaInstalled,
} from '@wix/members-area-integration-kit';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EcomPlatformInstallationState} from '../services/EcomPlatformInstallationState';
import {EcomComponent} from '@wix/ecom-platform-sdk';
import {getEcomPages} from '../../common/appManifest/appManifest';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {STORES_APP_DEF_ID} from '../../stores-editor-script/constants';
import {StyleParams} from '../services/StyleParams';
import {EditorSDK, PageRef, RouterRef} from '@wix/platform-editor-sdk';
import {EcomStyleParams} from '@wix/ecom-platform-sdk/dist/es/src/types';
import {ExperimentsApi} from '../../common/experiments/ExperimentsApi';
import {createWishlistApi, InstallationOrigin} from './wishlist/wishlistActions';
import {DependantApps} from '../services/DependantApps';
import {translateFunctionFactory} from '../../common/translations/translations';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';

export async function addPage(sdk: EditorSDK, pageId: string, managingAppDefId: string, shouldNavigate: boolean) {
  managingAppDefId = pageId === PageMap.CHECKOUT ? ecomAppDefID : managingAppDefId;
  const pageData = (await sdk.tpa.add.component('', {
    appDefinitionId: ecomAppDefID,
    managingAppDefId,
    componentType: sdk.document.tpa.TPAComponentType.Page,
    page: {
      pageId,
      shouldNavigate,
    },
  })) as {
    compId: string;
    pageRef: PageRef;
    pageUriSEO: string;
    title: string;
  };

  await sdk.document.pages.setState('', {
    state: {
      [pageId]: [{id: pageData.pageRef.id}],
    },
  });
  return pageData.pageRef;
}

export function addWidget(sdk: EditorSDK, widgetId: string, addToAllPages: boolean) {
  return sdk.tpa.add.component('', {
    appDefinitionId: ecomAppDefID,
    componentType: sdk.tpa.TPAComponentType.Widget,
    widget: {
      widgetId,
      allPages: addToAllPages,
    },
  });
}

export async function addRouter(sdk: EditorSDK, prefix: string) {
  const routerRef = await sdk.routers.getByPrefix('', {prefix});
  if (routerRef) {
    return routerRef;
  }

  return sdk.routers.add('', {prefix});
}

export function getRouter(sdk: EditorSDK, routerRef: RouterRef) {
  return sdk.routers.get('', {routerRef});
}

export function connectPageToRouter(
  sdk: EditorSDK,
  {pageRef, routerRef, pageRoles}: {pageRef: PageRef; routerRef: RouterRef; pageRoles: string[]}
) {
  return sdk.routers.pages.connect('', {
    pageRef,
    routerRef,
    pageRoles,
  });
}

export async function addMembersArea(
  editorType: string,
  firstInstall: boolean,
  biData?: {origin?: string},
  siteCreationFlow?: boolean
) {
  await registerMembersAreaApps(ECOM_MEMBERS_AREA_DEFAULT_PAGES);
  if (!firstInstall || editorType === 'ADI' || siteCreationFlow) {
    return Promise.resolve();
  }

  const options = biData && {biData};
  await installMembersArea(options);
  await addApplications(ECOM_MEMBERS_AREA_DEFAULT_PAGES, options);
}

export async function setStateForStoresPages(sdk: EditorSDK) {
  const allSitePages = await sdk.pages.data.getAll('');
  const storesPages = allSitePages.filter(({managingAppDefId}) => managingAppDefId === STORES_APP_DEF_ID);
  return Promise.all(
    storesPages.map((pageData) => {
      if (pageData.tpaPageId === PageMap.PRODUCT) {
        return sdk.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
      return Promise.resolve();
    })
  );
}

export async function setStateForPages(sdk: EditorSDK) {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  const ecomPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
  return Promise.all(
    ecomPages.map((pageData) => {
      if (pageData.tpaPageId === PageMap.CART || pageData.tpaPageId === PageMap.THANKYOU) {
        return sdk.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
      return Promise.resolve();
    })
  );
}

export function openEcomBillingPage(editorSDK: EditorSDK) {
  return editorSDK.editor.openBillingPage('', {premiumIntent: 'PAID', referrer: 'stores_app_product'});
}

export async function installWishlistApp({
  sdk,
  dependantApps,
  t,
  experimentsApi,
  webBiLogger,
}: {
  sdk: EditorSDK;
  dependantApps: DependantApps;
  t: (keyToTranslate: string) => string;
  experimentsApi: ExperimentsApi;
  webBiLogger: WebBiLogger;
}) {
  const wishlistApi = createWishlistApi({sdk, dependantApps, t, experimentsApi, webBiLogger});
  if (await isMembersAreaInstalled()) {
    return wishlistApi.installWishlist({origin: InstallationOrigin.Plugins, installMembersArea: false});
  } else {
    return wishlistApi.installWishlist({origin: InstallationOrigin.Plugins, installMembersArea: true});
  }
}

export function updateEcomPlatformInstallations(
  ecomPlatformInstallationState: EcomPlatformInstallationState,
  appDefId: string,
  installedComponents: EcomComponent[]
) {
  ecomPlatformInstallationState.addInstalledApp(appDefId, installedComponents);
}

export async function updateEcomPagesForPagesPanel(
  editorSDK: EditorSDK,
  ecomPlatformInstallationState: EcomPlatformInstallationState
) {
  const appDefId =
    ecomPlatformInstallationState.getInstalledAppDefIds().length === 1
      ? ecomPlatformInstallationState.getInstalledAppDefIds()[0]
      : ecomAppDefID;

  const tpaApplicationId = (await editorSDK.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
  const allSitePages = await editorSDK.pages.data.getAll('');
  const ecomPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);

  return Promise.all(
    ecomPages.map(async (pageData) => {
      if (pageData.tpaPageId === PageMap.CART || pageData.tpaPageId === PageMap.THANKYOU) {
        const pageRef: PageRef = {id: pageData.id, type: 'DESKTOP'};
        await editorSDK.pages.data.update('', {
          pageRef,
          data: {managingAppDefId: appDefId},
        });
        return editorSDK.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
    })
  );
}

export async function getEcomPlatformAppManifest(t, locale) {
  if (!t) {
    t = await translateFunctionFactory(locale);
  }
  const ecomPages = getEcomPages(t, getAppVersion(), locale);

  return {
    pages: {
      pageActions: {
        [PageMap.CART]: ecomPages[PageMap.CART].actions,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].actions,
      },
      pageSettings: {
        [PageMap.CART]: ecomPages[PageMap.CART].settings,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].settings,
      },
      pageDescriptors: {
        [PageMap.CART]: ecomPages[PageMap.CART].descriptors,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].descriptors,
      },
    },
  };
}

export async function setThankYouPageStyleParams({
  sdk,
  ecomPlatformInstallationState,
  styleParams,
}: {
  sdk: EditorSDK;
  ecomPlatformInstallationState: EcomPlatformInstallationState;
  styleParams?: EcomStyleParams;
  experimentsApi: ExperimentsApi;
}) {
  if (!ecomPlatformInstallationState.isMoreThanOneAppInstalled()) {
    const applicationId = (await sdk.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
    const styleParamsHandler = new StyleParams(sdk, applicationId);
    return styleParamsHandler.setThankYouPageStyleParams(styleParams);
  }
}
